import { FormControl, FormLabel, Checkbox, FormHelperText } from "@tempo/core";
import { useField } from "formik";
import React from "react";

type Props = {
  title: string;
  name: string;
  label: string;
  disabled?: boolean;
  hint?: string;
};

const PortalToggleField: React.FC<Props> = ({
  title,
  name,
  label,
  disabled,
  hint,
  ...props
}) => {
  const [field] = useField(name);

  return (
    <FormControl mx="4" maxW={["100%", "250px"]} mb={["4", "0"]}>
      <FormLabel fontSize="xl">{title}</FormLabel>
      <Checkbox
        size="lg"
        mt="1"
        isDisabled={disabled}
        isChecked={field.value}
        {...props}
        {...field}
      >
        {label}
      </Checkbox>

      {hint && (
        <FormHelperText fontStyle="italic" fontSize="xs">
          {hint}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default PortalToggleField;
